<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        詳細
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text v-if="passData">
        <SizeBox height="22" />
        <p class="text-Heading5 Regular Secondary900--text">操作紀錄</p>

        <v-card flat color="Secondary100">
          <v-card-text>
            <v-row>
              <v-col cols="4" sm="2">操作使間：</v-col>
              <v-col>{{ passData.created_at | formatTime }}</v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="2">頁面：</v-col>
              <v-col>{{ passData.page }}</v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="2">動作：</v-col>
              <v-col>{{ passData.action }}</v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="2">IP：</v-col>
              <v-col>{{ passData.ip }}</v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="2">操作人員：</v-col>
              <v-col>{{ passData.user.name }}</v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="2">資料來源：</v-col>
              <v-col>{{ passData.source }}</v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="2">資料異動前：</v-col>
              <v-col class="text-pre-wrap">
                {{ passData.previous_value | parseObject }}
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="2">資料異動後：</v-col>
              <v-col class="text-pre-wrap">
                {{ passData.changed_value | parseObject }}
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <SizeBox height="22" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         *
         */
      })
    }
  },

  filters: {
    parseObject(v) {
      return typeof v === 'object' && Object.entries(v).length
        ? JSON.stringify(v)
            .trim()
            .replace(/{"|}|"}|,"/g, `\n`)
            .replace(/":/g, ' : ')
        : 'NULL';
    }
  },

  data() {
    return {
      //
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      //
    },
    reset() {
      //
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
