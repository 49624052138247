import { api } from '.';

export function getOperationLogList(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/operationLogs/getOperationLogList', payload);
}

export function getOperationLogFilters() {
  return api('/v1/operationLogs/getOperationLogFilters');
}
