<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p"></v-card>

    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
          :exclude="['detail']"
        />
      </v-card-text>
      <DataTable
        :list="result.list"
        :fields="headers"
        :total="result.total"
        :loading="isLoading"
        :labels="{
          is_successful: [
            { text: '成功', value: true, color: 'Primary200' },
            { text: '失敗', value: false, color: 'Error300' }
          ]
        }"
        :replace="['created_at|formatTime', 'detail']"
        @get-list="fetchData"
      >
        <template #item.detail="{ item }">
          <v-btn
            rounded
            @click="viewDetail('Detail', item)"
            color="Secondary100"
            class="mx-2 my-3"
            depressed
            small
          >
            <v-icon size="18" class="mr-1">mdi-filter-variant</v-icon>
            詳細
          </v-btn>
        </template>
      </DataTable>
    </v-card>

    <DetailForm
      :value="showingForm === 'Detail'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import {
  getOperationLogList,
  getOperationLogFilters
} from '@/api/operationLogs';

import DetailForm from '@/components/system-log/operation/DetailForm.vue';

export default {
  components: { DetailForm },
  async created() {
    this.fetchData();
  },

  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '操作時間',
          value: 'created_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center'
        },
        {
          text: '頁面',
          value: 'page',
          align: 'center'
        },
        {
          text: '動作',
          value: 'action',
          align: 'center'
        },
        {
          text: '操作人員',
          value: 'user.name',
          align: 'center'
        },
        {
          text: 'IP',
          value: 'ip',
          align: 'center'
        },
        {
          text: '結果',
          value: 'is_successful',
          align: 'center'
        },
        {
          text: '詳細內容',
          value: 'detail',
          align: 'center'
        }
      ],
      filterOptions: [
        {
          label: '操作人員',
          type: 'text',
          name: 'filter.user_name'
        },
        {
          label: '頁面',
          type: 'select',
          name: 'filter.page',
          items: []
        },
        {
          label: '動作',
          type: 'select',
          name: 'filter.action',
          items: []
        },
        {
          label: '結果',
          type: 'select',
          name: 'filter.is_successful',
          items: [
            { text: '成功', value: true },
            { text: '失敗', value: false }
          ]
        },
        {
          label: 'IP',
          type: 'text',
          name: 'filter.ip'
        }
      ],
      filterNames: {}
    };
  },

  watch: {
    filterNames({ page, action }) {
      if (page) {
        const target = this.filterOptions.find(
          ({ name }) => name === 'filter.page'
        );
        this.$set(target, 'items', page);
      }
      if (action) {
        const target = this.filterOptions.find(
          ({ name }) => name === 'filter.action'
        );
        this.$set(target, 'items', action);
      }
    }
  },

  mounted() {
    this.fetchFilters();
  },

  methods: {
    async fetchFilters() {
      const filterNames = await getOperationLogFilters();
      this.$set(this, 'filterNames', filterNames);
    },
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;

      const SessionLogs = await getOperationLogList({
        ...condition,
        ...this.filter
      });

      if (!SessionLogs.error) {
        this.result.total = SessionLogs.total;
        this.result.page = SessionLogs.page;
        this.$set(
          this.result,
          'list',
          SessionLogs.items.map((item) => ({
            ...item
          }))
        );
      }

      this.isLoading = false;
    },
    viewDetail(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
